import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { Dialog, DialogActions, DialogContent } from "@mui/material";
import { toast } from "react-toastify";
import { patchReq } from "../../utils/apiHandlers";
import { fetchUserData } from "../../redux/Slice/User/user";
import { isYupError, parseYupError } from "../../utils/Yup";
import { nameValidation } from "../../utils/validation";
import { reactIcons } from "../../utils/icons";
import Loader from "../loaders/Loader";

const NameUpdate = ({ userData, handleNameLogs, openView }) => {
  const [open, setOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const dispatch = useDispatch();
  const [form, setForm] = useState({
    lastname: "",
    firstname: ""
  });
  const [formError, setFormError] = useState({
    lastname: "",
    firstname: ""
  });

  useEffect(() => {
    if (userData) {
      setForm({
        ...form,
        firstname: userData?.firstname,
        lastname: userData?.lastname
      });
    }
  }, [userData, userData?.firstname, userData?.lastname]);

  // input value update function
  const handleChange = (e) => {
    const { name, value } = e.target;
    setForm({
      ...form,
      [name]: value
    });
  };

  // update email address function
  const hanldeUpdatename = async () => {
    if (isLoading) return;
    try {
      setIsLoading(true);
      await nameValidation.validate(form, {
        abortEarly: false
      });

      const res = await patchReq("/users/me", form);
      const { status, error } = res;
      if (status) {
        setIsLoading(false);
        setOpen(false);
        dispatch(fetchUserData());
        toast.success("Name Updated Successfully");
        setForm({
          lastname: "",
          firstname: ""
        });
        setFormError({
          lastname: "",
          firstname: ""
        });
      } else {
        toast.error(error?.message);
        setIsLoading(false);
      }
    } catch (error) {
      if (isYupError(error)) {
        setFormError(parseYupError(error));
        setIsLoading(false);
      } else {
        toast.error(error);
        setIsLoading(false);
      }
    }
  };

  return (
    <>
      {!openView && (
        <button
          type="button"
          onClick={() => setOpen(true)}
          className="gradient-background text-white p-2.5 rounded-lg flex items-center justify-center gap-2 border text-22 font-normal absolute top-[2px] right-[2px] bottom-[2px] h-[46px] w-[46px]"
        >
          {reactIcons.edit}
        </button>
      )}
      {openView && (
        <button
          type="button"
          onClick={handleNameLogs}
          className="w-full text-right text-[11px] text-[#ee0000] underline mt-2 leading-none"
        >
          View Username Update Logs
        </button>
      )}

      <Dialog
        open={open}
        onClose={() => setOpen(false)}
        scroll={"paper"}
        fullWidth={true}
        maxWidth={"sm"}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <DialogContent dividers={true}>
          <div className="input_field mt-2">
            <label className=" block font-semibold text-black text-14px md:text-18 mb-3">
              First Name
            </label>
            <input
              type="text"
              placeholder="Enter Your First Name"
              name="firstname"
              onChange={handleChange}
              value={form?.firstname}
              className="bg-[#fff] border border-[#EE0000]"
            />
            {formError.firstname && (
              <div className="form-eror">{formError.firstname}</div>
            )}
          </div>
          <div className="input_field mt-5">
            <label className=" block font-semibold text-black text-14px md:text-18 mb-3">
              Last Name
            </label>
            <input
              type="text"
              placeholder="Enter Your First Name"
              name="lastname"
              onChange={handleChange}
              value={form?.lastname}
              className="bg-[#fff] border border-[#EE0000]"
            />
            {formError.lastname && (
              <div className="form-eror">{formError.lastname}</div>
            )}
          </div>
          <div>
            <p className="text-[11px] mt-6">
              <strong>Note:</strong> You can update your fullname only once.
            </p>
          </div>
        </DialogContent>
        <DialogActions>
          <button
            onClick={() => setOpen(false)}
            type="button"
            className="btn btn-danger min-w-[100px] md:min-w-[170px] text-[#EE0000] bg-white border border-[#EE0000]"
          >
            Cancel
          </button>
          <button
            onClick={hanldeUpdatename}
            type="button"
            className="btn btn-danger min-w-[120px] md:min-w-[200px] bg-[#EE0000]"
          >
            {isLoading ? <Loader /> : "Update"}
          </button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default NameUpdate;
